var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-right", attrs: { cols: "4" } }, [
                _vm._v("Template:")
              ]),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { change: _vm.selectTemplate },
                      model: {
                        value: _vm.selectedId,
                        callback: function($$v) {
                          _vm.selectedId = $$v
                        },
                        expression: "selectedId"
                      }
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v("Select a Template")
                      ]),
                      _vm._l(_vm.templates, function(t) {
                        return _c(
                          "b-form-select-option",
                          { key: t.id, attrs: { value: +t.id } },
                          [_vm._v(" " + _vm._s(t.name) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.selected
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "my-3 text--black",
                      attrs: { "no-body": "", "bg-variant": "light" }
                    },
                    [
                      _c("Editor", {
                        attrs: {
                          "api-key":
                            "83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu",
                          init: _vm.tinymceConfig
                        },
                        model: {
                          value: _vm.selected.content,
                          callback: function($$v) {
                            _vm.$set(_vm.selected, "content", $$v)
                          },
                          expression: "selected.content"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _vm.selected.previous
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "danger",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.revert.apply(null, arguments)
                                    }
                                  }
                                },
                                [_vm._v(" Revert Last Changes ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selected.type === "contract"
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "info",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.preview.apply(null, arguments)
                                    }
                                  }
                                },
                                [_vm._v(" Preview PDF ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "success",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.save.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Save Changes")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "secondary",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.cancel.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.selected && _vm.selected.previous
        ? _c(
            "b-modal",
            {
              attrs: {
                size: "md",
                title: "Revert Last Changes",
                "button-size": "sm",
                "ok-variant": "danger",
                "ok-title": "Revert",
                "no-close-on-backdrop": "",
                "no-close-on-esc": ""
              },
              on: { ok: _vm.revert },
              model: {
                value: _vm.showRevert,
                callback: function($$v) {
                  _vm.showRevert = $$v
                },
                expression: "showRevert"
              }
            },
            [
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "Are you sure you want to revert the last changes made to this template?"
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }